import { gettext } from '@eventbrite/i18n';
import { SearchBarContainerWithProviders } from '@eventbrite/search-takeover';
import classNames from 'classnames';
import React from 'react';
import '../../global.scss';
import { GlobalNavProvider, useGlobalNavContext } from '../../state';
import {
    creatorDesktopItems,
    getSigninOrSignupUrl,
    isReferrerMLP,
    updateQueryStringParameter,
} from '../../utils';
import { Logo, MobileDropdown, NavLink } from '../common';
import { NavItemList } from '../common/components/NavItemList';
import { GlobalNavProps, NavItemType } from '../types';
import styles from './GlobalNav.module.scss';

export const GlobalNavContent = () => {
    const { navigation, env, positionSticky, searchBar, url } =
        useGlobalNavContext();
    const { desktop } = navigation ?? {};
    const stickyDesktopClass = positionSticky?.desktop ? 'desktopSticky' : '';
    const stickyMobileClass = positionSticky?.mobile ? 'mobileSticky' : '';

    const navClasses = classNames(
        styles.navContainer,
        stickyDesktopClass,
        stickyMobileClass,
    );

    const { mobile } = navigation ?? {};
    const dropdownItems = mobile as NavItemType[];

    const mainClassnames = classNames(styles.main, styles.withSearchDropdown);

    const findMyTicketsUrl = () => {
        return updateQueryStringParameter(
            env?.signupUrl ?? '/signin/signup',
            'referrer',
            '/mytickets',
        );
    };

    return (
        <header className={mainClassnames}>
            <nav
                aria-label={gettext('Main Navigation').toString()}
                data-testid="global-nav"
                className={navClasses}
            >
                <div className={styles.navWrapper}>
                    <div className={styles.mobileNavTopSection}>
                        <div className={styles.logo} tabIndex={-1}>
                            <Logo />
                        </div>
                        <div className={styles.mobileNavLinks}>
                            <ul className={styles.mobileNavLinkContainer}>
                                <li className={styles.mobileNavListLink}>
                                    <NavLink
                                        heapIdentifier={'find-my-tickets'}
                                        translatedText={gettext(
                                            'Find my tickets',
                                        )}
                                        path={findMyTicketsUrl()}
                                        className={styles.mobileLogin}
                                    />
                                </li>
                                <li className={styles.mobileNavListLink}>
                                    <NavLink
                                        heapIdentifier={'login'}
                                        translatedText={gettext('Log In')}
                                        path={getSigninOrSignupUrl(
                                            env?.signinUrl,
                                        )}
                                        className={styles.mobileLogin}
                                    />
                                </li>
                                <li className={styles.mobileNavListLink}>
                                    <NavLink
                                        heapIdentifier={'signup'}
                                        translatedText={gettext('Sign Up')}
                                        path={getSigninOrSignupUrl(
                                            env?.signupUrl,
                                        )}
                                        className={styles.mobileSignup}
                                    />
                                </li>
                                <MobileDropdown dropdownItems={dropdownItems} />
                            </ul>
                        </div>
                    </div>
                    {!isReferrerMLP(url) && (
                        <div className={styles.searchBar}>
                            <SearchBarContainerWithProviders
                                initialQuery={searchBar?.searchQuery}
                            />
                        </div>
                    )}
                    <div className={styles.inlineLinks}>
                        <NavItemList
                            inSearchDropdown
                            navItems={
                                isReferrerMLP(url)
                                    ? creatorDesktopItems(desktop)
                                    : desktop
                            }
                            serverUrl={env?.serverUrl ?? ''}
                            signinUrl={getSigninOrSignupUrl(env?.signinUrl)}
                            signupUrl={getSigninOrSignupUrl(env?.signupUrl)}
                            findMyTicketsUrl={findMyTicketsUrl()}
                        />
                        <ul className={styles.inlineDropdown}>
                            <MobileDropdown dropdownItems={dropdownItems} />
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

const GlobalNav: React.FC<GlobalNavProps> = ({
    positionSticky,
    searchBar,
    logo,
    navigation,
    user,
    env,
    url,
}) => {
    return (
        <GlobalNavProvider
            positionSticky={positionSticky}
            searchBar={searchBar}
            logo={logo}
            user={user}
            env={env}
            navigation={navigation}
            url={url}
        >
            <GlobalNavContent />
        </GlobalNavProvider>
    );
};

export default GlobalNav;
